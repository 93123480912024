import React from "react"
// import { Link } from "gatsby"
import classNames from 'classnames'
import ScheduleADemoButton from '../components/schedule-a-demo-button'


const compareBanner = ({content}) => {
  return (
    <div className={classNames('comparison-hero', 
          {"height-auto" : content.height === "auto"},
          {"section-compared" : content.sectionCompared === "compared"})}>

      <div className="container">
        <div className="banner-details">
          <h1 className="section-heading">{content.title}</h1>
          <p className="section-text">{content.text}</p>

          <div className="btn-wrapper">
            <a href="https://app.billsby.com/registration" target="_blank" rel="noopener noreferrer" className="btn btn-orange">Sign up for free</a>
            <ScheduleADemoButton buttonColor="white"/>
          </div>
        </div>
        {
          content.billsbyImage ? <div className="billsby-holder">
          <img src={content.billsbyImage} className="img-bg" alt="billsby" />
        </div> : ''
        }
        {
          content.compLogo ? 
          <div className="comparison-image-holder">
            <img src={content.compLogo} className="img-logo" alt="logo" />
            <img src={content.compareImage} className="img-bg" alt="bg" alt />
          </div> : ''
        }
      </div>
    </div>
  )
}

export default compareBanner